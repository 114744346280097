'use strict';

(function (modules = {}) {
  Object.keys(modules).forEach(name => {
    if (typeof window[name] !== 'undefined') {
      throw new Error(
        `Unable to populate ${name} module since the namespace is already taken.`
      );
    }
    window[name] = modules[name];
  });
})(
  (function ({ jQuery: $ }) {
    var CACHE_KEY = 'main_modal';
    var CACHE_MAXAGE = 6.048e8; // 1주일에 해당하는 밀리초

    function readCache() {
      var cacheString = localStorage.getItem(CACHE_KEY) || '[]';
      var cache = JSON.parse(cacheString);
      return cache;
    }

    function writeCache(modalId) {
      var now = Date.now();
      var cache = readCache();
      var newCache = cache.some(function (item) {
        return item.id === modalId;
      })
        ? cache
        : cache.concat({
            id: modalId,
            time: now,
          });
      var newCacheString = JSON.stringify(newCache);
      localStorage.setItem(CACHE_KEY, newCacheString);
    }

    function pruneCache() {
      var now = Date.now();
      var cache = readCache();
      var newCache = cache.filter(function (item) {
        return now - item.time < CACHE_MAXAGE;
      });
      var newCacheString = JSON.stringify(newCache);
      localStorage.setItem(CACHE_KEY, newCacheString);
    }

    function getDismissedIds() {
      var cache = readCache();
      var ids = cache.map(function (item) {
        return item.id;
      });
      return ids;
    }

    function init() {
      pruneCache();

      var dismissed = getDismissedIds();

      $('.js_modal').each(function () {
        var $modal = $(this);
        var modalId = $modal.data('id');

        if (dismissed.includes(modalId)) {
          return;
        }

        $modal.addClass('modal_open');
      });

      $(document)
        .on('click', '.js_modal [data-action="close"]', function handleClose() {
          var $modal = $(this).closest('.js_modal');
          $modal.removeClass('modal_open');
        })
        .on(
          'click',
          '.js_modal [data-action="dismiss"]',
          function handleDismiss() {
            var $modal = $(this).closest('.js_modal');
            var modalId = $modal.data('id');

            if (!modalId) {
              console.error('Modal is missing id: '.concat(modalId));
            }

            $modal.removeClass('modal_open');
            writeCache(modalId);
          }
        );
    }

    $(function () {
      init();
    });
  })(
    (function (jQuery) {
      requireModule('jQuery', typeof jQuery === 'function');

      function requireModule(name, predicate) {
        if (!predicate) {
          throw new Error(
            `Unable to find ${name} module. Please make sure it is loaded before this script.`
          );
        }
      }

      return { jQuery };
    })(window.jQuery)
  )
);
